import React from 'react';
import TradingViewWidget from './TradingViewWidget';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router';

export default function SymbolChart() {
    const { symbol } = useParams();

    return (
        <Grid>
            <Grid container xs={12} md={2} alignItems="flex-start" justifyContent="center">
                <Box>{symbol}</Box>
            </Grid>
            <Grid container xs={12} md={10}>
                <TradingViewWidget symbol={symbol} />
            </Grid>
        </Grid>
    )
}