import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router";
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import SymbolChart from './SymbolChart';
import theme from './theme';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/chart/:symbol" element={<SymbolChart />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.querySelector('#root'),
);
