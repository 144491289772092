import React from 'react'
import PropTypes from 'prop-types'
import DailyBarChart from './DailyBarChart';
import _ from 'lodash';
import moment from 'moment';

const format = s => {
    return moment(s).format('MMMDD');
}
export default function DailyWinLossChart(props) {
    const {trades} = props;
    const chartData = _.flatten(
        _.values(
            _.mapValues(
                _.groupBy(trades, t => format(t.closeTime)),
                arr => {
                    return {
                        date: format(arr[0].closeTime),
                        wins: _.filter(arr, a => a.grossProfit > 0).length,
                        losses: _.filter(arr, a => a.grossProfit < 0).length
                    }
                }
            )));
    const seriesinfo = [
        {
            dataKey: 'wins',
            label: 'Wins',
        },
        {
            dataKey: 'losses',
            label: 'Losses',
        }
    ]
    return (<DailyBarChart data={chartData} seriesinfo={seriesinfo} xAxis={[{scaleType:'band', dataKey: 'date'}]}/>)
    // return (<></>)
}
DailyWinLossChart.propTypes = {
    trades: PropTypes.array.isRequired
}