import React from 'react';
import PropTypes from 'prop-types'
import { Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import {
    cheerfulFiestaPalette,
  } from '@mui/x-charts/colorPalettes';

export default function DailyBarChart(props) {
    const { data, seriesinfo, xAxis } = props;
    return (
        <Paper style={{height: '600px', width: '75%'}}>
            <BarChart dataset={data} series={seriesinfo} xAxis={xAxis} colors={cheerfulFiestaPalette} />
        </Paper>
    )
}

DailyBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    seriesinfo: PropTypes.array.isRequired,
    xAxis: PropTypes.array.isRequired
}