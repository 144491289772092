import React from 'react'
import PropTypes from 'prop-types'
import DailyBarChart from './DailyBarChart';
import _ from 'lodash';
import moment from 'moment';

const format = s => {
    return moment(s).format('MMMDD');
}
export default function DailyProfitLossChart(props) {
    const {trades} = props;
    const chartData = _.flatten(
        _.values(
            _.mapValues(
                _.groupBy(trades, t => format(t.closeTime)),
                arr => {
                    return {
                        date: format(arr[0].closeTime),
                        profit: _.sumBy(_.filter(arr, a => a.grossProfit > 0), 'grossProfit'),
                        loss: Math.abs(_.sumBy(_.filter(arr, a => a.grossProfit < 0), 'grossProfit')),
                        net: _.sumBy(arr, 'grossProfit')
                    }
                }
            )));
    const seriesinfo = [
        {
            dataKey: 'profit',
            label: 'Profit',
        },
        {
            dataKey: 'loss',
            label: 'Loss',
        },
        {
            dataKey: 'net',
            label: 'Net',
        }
    ]
    return (<DailyBarChart data={chartData} seriesinfo={seriesinfo} xAxis={[{scaleType: 'band', dataKey: 'date'}]} />)
    // return (<></>)
}

DailyProfitLossChart.propTypes = {
    trades: PropTypes.array.isRequired
}